import "./src/styles/global.css"

import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import ThemeWrapper from "./src/components/common/theme-wrapper"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <ThemeWrapper>{element}</ThemeWrapper>
}
